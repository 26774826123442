<template>
    <modal :open="findModal" @close="$emit('closeModal')" @save="$emit('closeModal')" :modalData="modalData">
      <template v-slot:modalContent>
        <DonateTable :targetId="targetId"/>
      </template>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { Donations } from "@/pages/reports";

export default {
  props: ['findModal'],
  emits: ['closeModal'],
  components:{
    Modal: () => import("@/components/common/modal"),
    DonateTable: Donations
  },
  data() {
    return {
      modalData: {
        title: 'Donations',
        saveBtnTxt: 'Ok',
        width: 1200,
        maxHeight: '700px'
      },
      targetId: null
    }
  },
  computed: {
    ...mapGetters(["currentTargetModel"])
  },
  watch: {
      'currentTargetModel': function (newValue) {
          this.targetId = newValue ? newValue.id : null;
      }
  },
  created() {
    if (this.currentTargetModel) this.targetId = this.currentTargetModel.id;
  }
}
</script>
